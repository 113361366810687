.App {
  text-align: center;
  margin-top: 30px;
}




.inputbox-label {
  font-size: 16px;
  color: #a04511;

}

.inputbox-input-outer {
  padding-top: 5px;
  padding-bottom: 5px;
  border-top: 2px solid #cf8c66;
  border-bottom: 2px solid #cf8c66;
}

.inputbox-input {
  border-width: 0;
  padding-left: 20px;
  width: 100%;
  outline: none;
  font-size: 18px;
}

.password-form-title {
  font-size: 30px;
  font-weight: 500;
  color: #cf8c66;

}
.password-form-inputbox-outer {
  margin-top: 20px;
  margin-bottom: 10px;
}

.password-form-buttons {
  margin-top: 40px;
  margin-bottom: 50px;

}

.app-button-outer {
  border-radius: 30px;
  background-color: #c96f2c;
  color: #000;
  padding-top: 15px;
  padding-bottom: 15px;
}